import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from '../translations'

void i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: ['en'],

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
    skipOnVariables: false
  },

  resources,
})

export default i18n
